import { apiRequest, RequestInput } from 'src/api/apiRequest'
import { useAlerts } from 'src/features/alerts/state/useAlerts'
import { guidGenerator } from 'src/features/Map/utils/functions'
import { BoardPageFiltersType } from 'src/features/saved/types'
import { getBrandById, getLocationById, getProductById } from 'src/features/saved/utils/requests'
import {
    Board,
    BoardItemType,
    Brand,
    FavoriteBrand,
    FavoriteLocation,
    FavoriteProduct,
    Favorites,
    InputBoardItem,
    InputCreateBoard,
    InputDeleteBoard,
    InputGetById,
    InputSharedBoard,
    InputUpdateBoard,
    Location,
    Product,
} from 'src/types/schema/graphql'
import { LoadingStatus } from 'src/types/ui'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { getSharedBoardQuery } from '../utils/graph'
import { addItemToBoardMutation } from '../utils/graph/addItemToBoardMutation'
import { createBoardMutation } from '../utils/graph/createBoardMutation'
import { deleteBoardMutation } from '../utils/graph/deleteBoardMutation'
import { updateBoardMutation } from '../utils/graph/updateBoardMutation'
import { getBoardByIdQuery } from '../utils/graph/getBoardByIdQuery'
import { getBoardsByAuthenticatedUserQuery } from '../utils/graph/getBoardsByAuthenticatedUser'
import { removeItemFromBoardMutation } from '../utils/graph/removeItemFromBoardMutation'
import { useFavoriteActionsStore } from './useFavoriteActionsStore'

interface IDefaultData {
    boards: Board[]
    curatedBoards: Board[]
    newBoardTitle: string
    newBoardDescription: string
    createBoardFormSubmitted: boolean
    updateBoardData: {
        boardId: string
        title: string
        description: string
    }
    boardDetailPageData: Board | null
    sharedBoardDetailPageData: Board | null
    status: LoadingStatus
    boardProducts: Product[]
    boardBrands: Brand[]
    boardLocations: Location[]
    sharedLocationIds: FavoriteLocation[]
    sharedProductIds: FavoriteProduct[]
    sharedBrandIds: FavoriteBrand[]
    allFavorites: Favorites
}
const defaultData: IDefaultData = {
    boards: [],
    curatedBoards: [],
    newBoardTitle: '',
    newBoardDescription: '',
    createBoardFormSubmitted: false,
    updateBoardData: {
        boardId: '',
        title: '',
        description: '',
    },
    boardDetailPageData: null,
    sharedBoardDetailPageData: null,
    status: 'loading',
    boardProducts: [],
    boardBrands: [],
    boardLocations: [],
    sharedProductIds: [],
    sharedBrandIds: [],
    sharedLocationIds: [],
    allFavorites: null,
}
interface IuseSavedBoardsStore extends IDefaultData {
    setBoards: (boards: Board[]) => void
    setCuratedBoards: (curatedBoards: Board[]) => void
    createBoardModalOpen: boolean
    setCreateBoardModalOpen: (open: boolean) => void
    updateBoardModalOpen: boolean
    setUpdateBoardModalOpen: (open: boolean) => void
    setNewBoardTitle: (title: string) => void
    setNewBoardDescription: (description: string) => void
    setCreateBoardFormSubmitted: (submitted: boolean) => void
    createBoard: () => Promise<Boolean>
    updateBoard: () => Promise<Boolean>
    setUpdateBoardData: (boardData: Partial<InputUpdateBoard>) => void
    populateSavedBoardsList: () => Promise<void>
    activeCreatedBoard: Board | null
    handleCreateBoard: () => void
    addItemToBoard: (boardId: string) => Promise<void>
    removeItemFromBoard: (boardId: string) => Promise<void>
    itemExistsOnBoard: (boardId: string, itemId: string, itemType: BoardItemType) => boolean
    getBoardById: (boardId: string) => Promise<Board>
    setBoardDetailPageData: (board: Board) => void
    populateBoardDetailPage: (board: Board) => Promise<void>
    getBoardProducts: () => Promise<Product[]>
    getBoardBrands: () => Promise<Brand[]>
    getBoardLocations: () => Promise<Location[]>
    deleteBoard: (boardId: string) => Promise<boolean>
    activeBoard: Board
    setActiveBoard: (board: Board) => void
    deleteBoardModalOpen: boolean
    setDeleteBoardModalOpen: (open: boolean) => void
    customBoardActiveFilter: BoardPageFiltersType
    setCustomBoardActiveFilter: (filter: BoardPageFiltersType) => void
    getSharedBoard: (boardId: string, userId: string) => Promise<Board>
    getCuratedBoards: (curatedBoardsIdList: {boardId: string, userId: string}[]) => Promise<Board[]>
    setStatus: (status: LoadingStatus) => void
    selectedBoardsList: string[]
    setSelectedBoardsList: (boardIds: string[]) => void
}

export const useSavedBoardsStore = create<IuseSavedBoardsStore>()(
    devtools(
        (set, get) => ({
            ...defaultData,
            setBoards: (boards) => set({ boards }),
            setCuratedBoards(curatedBoards) {
                set({ curatedBoards })
            },
            createBoardModalOpen: false,
            setCreateBoardModalOpen: (createBoardModalOpen) => set({ createBoardModalOpen }),
            updateBoardModalOpen: false,
            setUpdateBoardModalOpen: (updateBoardModalOpen) => set({ updateBoardModalOpen }),
            setUpdateBoardData: (
                data: Partial<{ boardId: string; title: string; description: string }>
            ) => set((state) => ({ updateBoardData: { ...state.updateBoardData, ...data } })),
            setNewBoardTitle: (newBoardTitle) => set({ newBoardTitle }),
            setNewBoardDescription: (newBoardDescription) => set({ newBoardDescription }),
            setCreateBoardFormSubmitted: (createBoardFormSubmitted) =>
                set({ createBoardFormSubmitted }),
            createBoard: async () => {
                const createBoardInput: InputCreateBoard = {
                    title: get().newBoardTitle,
                    description: get().newBoardDescription,
                }
                const input: RequestInput<InputCreateBoard> = {
                    query: createBoardMutation,
                    args: {
                        input: createBoardInput,
                    },
                    file: 'useSavedBoardsStore.ts',
                    endpoint: 'CreateBoard',
                    publicQuery: false,
                }
                const { success, data } = await apiRequest<Board, InputCreateBoard>(input)
                if (success) {
                    useAlerts.getState().addAlert({
                        id: 'board-create-success',
                        title: 'Board created successfully.',
                        theme: 'default',
                        duration: 6,
                    })
                    set({ activeCreatedBoard: data })
                } else {
                    useAlerts.getState().addAlert({
                        id: 'board-create-error',
                        title: 'There was a problem creating your board.',
                        theme: 'error',
                        duration: 6,
                    })
                }
                return success
            },
            updateBoard: async () => {
                const input: RequestInput<InputUpdateBoard> = {
                    query: updateBoardMutation,
                    args: {
                        input: get().updateBoardData,
                    },
                    file: 'useSavedBoardsStore.ts',
                    endpoint: 'UpdateBoard',
                    publicQuery: false,
                }
                const { success } = await apiRequest<Board, InputUpdateBoard>(input)
                if (success) {
                    useAlerts.getState().addAlert({
                        id: 'board-update-success',
                        title: 'Board updated successfully.',
                        theme: 'default',
                        duration: 6,
                    })
                    get().populateSavedBoardsList()
                } else {
                    useAlerts.getState().addAlert({
                        id: 'board-update-error',
                        title: 'There was a problem updating your board.',
                        theme: 'error',
                        duration: 6,
                    })
                }
                return success
            },
            populateSavedBoardsList: async () => {
                const input: RequestInput<{}> = {
                    query: getBoardsByAuthenticatedUserQuery,
                    args: { input: {} },
                    file: 'useSavedBoardsStore.ts',
                    endpoint: 'getBoardsByAuthenticatedUser',
                    publicQuery: false,
                }
                const { data } = await apiRequest<Board[], {}>(input)
                set({ boards: data, status: 'ready' })
            },
            activeCreatedBoard: null,
            handleCreateBoard: async () => {
                set({ createBoardModalOpen: false })
                await get()
                    .createBoard()
                    .then((success) => {
                        success && useSavedBoardsStore.getState().populateSavedBoardsList()
                    })
            },
            addItemToBoard: async (boardId: string) => {
                const input: RequestInput<InputBoardItem> = {
                    query: addItemToBoardMutation,
                    args: {
                        input: {
                            boardId,
                            itemId: useFavoriteActionsStore.getState().activeFavoriteItem?.id,
                            itemType: useFavoriteActionsStore.getState().activeFavoriteType,
                        },
                    },
                    file: 'useSavedBoardsStore.ts',
                    endpoint: 'addItemToBoard',
                    publicQuery: false,
                }
                const favoriteType = useFavoriteActionsStore.getState().activeFavoriteType
                if (favoriteType !== BoardItemType.Route) {
                    input.args.input.parentId =
                        (useFavoriteActionsStore.getState().activeFavoriteItem as Product)?.brand
                            ?.id || ''
                }
                const { success, data } = await apiRequest<Board, InputBoardItem>(input)
                if (success) {
                    useAlerts.getState().addAlert({
                        id: `add-item-to-board-success${guidGenerator()}`,
                        title: 'Item successfully added to board.',
                        theme: 'default',
                        duration: 6,
                    })
                    await useSavedBoardsStore.getState().populateSavedBoardsList()
                } else {
                    useAlerts.getState().addAlert({
                        id: 'add-item-to-board-error',
                        title: 'There was a problem adding this item to your board.',
                        theme: 'error',
                        duration: 6,
                    })
                }
            },
            removeItemFromBoard: async (boardId: string) => {
                const input: RequestInput<InputBoardItem> = {
                    query: removeItemFromBoardMutation,
                    args: {
                        input: {
                            boardId,
                            itemId: useFavoriteActionsStore.getState().activeFavoriteItem?.id,
                            itemType: useFavoriteActionsStore.getState().activeFavoriteType,
                        },
                    },
                    file: 'useSavedBoardsStore.ts',
                    endpoint: 'removeItemFromBoard',
                    publicQuery: false,
                }
                const favoriteType = useFavoriteActionsStore.getState().activeFavoriteType
                if (favoriteType !== BoardItemType.Route) {
                    input.args.input.parentId =
                        (useFavoriteActionsStore.getState().activeFavoriteItem as Product)?.brand
                            ?.id || ''
                }
                const { success, data } = await apiRequest<Board, InputBoardItem>(input)
                if (success) {
                    useAlerts.getState().addAlert({
                        id: 'remove-item-from-board-success',
                        title: 'Item successfully removed from board.',
                        theme: 'default',
                        duration: 6,
                    })
                    await get().populateSavedBoardsList()
                    const board = await get().getBoardById(get().boardDetailPageData?.id)
                    await get().populateBoardDetailPage(board)
                } else {
                    useAlerts.getState().addAlert({
                        id: 'remove-item-from-board-error',
                        title: 'There was a problem removing this item from your board.',
                        theme: 'error',
                        duration: 6,
                    })
                }
            },
            itemExistsOnBoard: (boardId: string, itemId: string, itemType: BoardItemType) => {
                const board = get().boards.find((board) => board?.id === boardId)
                if (itemType === BoardItemType.Brand)
                    return board?.favorites?.favoriteBrands?.some(
                        (brand) => brand.brandId === itemId
                    )
                if (itemType === BoardItemType.Location)
                    return board?.favorites?.favoriteLocations?.some(
                        (location) => location.locationId === itemId
                    )

                if (itemType === BoardItemType.Product)
                    return board?.favorites?.favoriteProducts?.some(
                        (product) => product.productId === itemId
                    )
                if (itemType === BoardItemType.Route)
                    return board?.routes?.some((route) => route.id === itemId)
            },
            getBoardById: async (boardId: string) => {
                set({ status: 'loading' })
                const input: RequestInput<InputGetById> = {
                    query: getBoardByIdQuery,
                    args: { input: { itemId: boardId } },
                    file: 'useSavedBoardsStore.ts',
                    endpoint: 'getBoardById',
                }
                const { success, data } = await apiRequest<Board, InputGetById>(input)
                if (success) {
                    return data
                } else {
                }
                return null
            },
            populateBoardDetailPage: async (board?: Board) => {
                useFavoriteActionsStore.getState().populateFavoriteStore()
                set({ boardDetailPageData: board })
                if (board) {
                    set({
                        sharedLocationIds:
                            board?.favorites?.favoriteLocations.filter((item) => item?.isActive) ||
                            [],
                        sharedBrandIds:
                            board?.favorites?.favoriteBrands.filter((item) => item?.isActive) || [],
                        // @TODO: Potentially add in active prodcuctId
                        sharedProductIds:
                            board?.favorites?.favoriteProducts.filter((item) => item?.isActive) ||
                            [],
                        allFavorites: board.favorites,
                    })
                    await Promise.all([
                        get().getBoardProducts(),
                        get().getBoardBrands(),
                        get().getBoardLocations(),
                    ])
                    set({ status: 'ready' })
                }
            },
            getBoardProducts: async () => {
                const activeProducts =
                    get().boardDetailPageData?.favorites?.favoriteProducts?.filter(
                        (item) => item?.isActive
                    )
                const arr: Product[] = await Promise.all(
                    activeProducts?.map(
                        async ({ productId, brandId }) => await getProductById(productId, brandId)
                    )
                )
                set({ boardProducts: arr })
                return arr
            },
            getBoardBrands: async () => {
                const activeBrands = get().boardDetailPageData?.favorites?.favoriteBrands?.filter(
                    (item) => item?.isActive
                )
                const arr: Brand[] = await Promise.all(
                    activeBrands?.map(async ({ brandId }) => await getBrandById(brandId))
                )
                set({ boardBrands: arr })
                return arr
            },
            getBoardLocations: async () => {
                const activeLocations =
                    get().boardDetailPageData?.favorites?.favoriteLocations?.filter(
                        (item) => item?.isActive
                    )
                const arr: Location[] = await Promise.all(
                    activeLocations?.map(
                        async ({ locationId, brandId }) =>
                            await getLocationById(locationId, brandId)
                    )
                )
                set({ boardLocations: arr })
                return arr
            },
            deleteBoard: async (boardId: string) => {
                const input: RequestInput<InputDeleteBoard> = {
                    query: deleteBoardMutation,
                    args: { input: { boardId: boardId } },
                    file: 'useSavedBoardsStore.ts',
                    endpoint: 'deleteBoard',
                }
                const { success } = await apiRequest<Board, InputDeleteBoard>(input)
                return success
            },
            setBoardDetailPageData: (board) => set({ boardDetailPageData: board }),
            activeBoard: null,
            setActiveBoard: (board) => set({ activeBoard: board }),
            deleteBoardModalOpen: false,
            setDeleteBoardModalOpen: (open) => set({ deleteBoardModalOpen: open }),
            customBoardActiveFilter: BoardPageFiltersType.All,
            setCustomBoardActiveFilter: (filter) => set({ customBoardActiveFilter: filter }),
            sharedBoardDetailPageData: null,
            setSharedBoardDetailPageData: (board) => set({ sharedBoardDetailPageData: board }),
            getSharedBoard: async (boardId: string, userId: string) => {
                const input: RequestInput<InputSharedBoard> = {
                    query: getSharedBoardQuery,
                    args: { input: { boardId, userId } },
                    file: 'useSavedBoardsStore.ts',
                    endpoint: 'getSharedBoard',
                    publicQuery: true,
                }
                const { success, data, error } = await apiRequest<Board, InputSharedBoard>(input)
                if (success) {
                    return data
                } else {
                    console.log('there was an error in getSharedBoard', error)
                }
                return null
            },
            getCuratedBoards: async (curatedBoardsIdList) => {
                const boards = curatedBoardsIdList.map(async ({ boardId, userId }) => {
                    const board = await get().getSharedBoard(boardId, userId);
                    return board;
                });
                const curatedBoardsList = await Promise.all(boards);
                set({ curatedBoards: curatedBoardsList, status: 'ready' });
                return curatedBoardsList;
            },
            setStatus: (status) => set({ status }),
            selectedBoardsList: [],
            setSelectedBoardsList: (boardIds) => set({ selectedBoardsList: boardIds }),
        }),
        { name: 'useSavedBoardsStore' }
    )
)
